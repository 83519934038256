@import '../../common/variables.css';

.horizontal {
  border-top: 1px solid var(--color-divider);
  width: 100%;
  height: 0;
}

.vertical {
  border-left: 1px solid var(--color-divider);
  height: 100%;
}
